let mybutton = document.getElementById("btn-back-to-top");

window.onscroll = function () {
    scrollFunction();
};

//----------- show back to top button -----------------
function scrollFunction() {
    if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
    ) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}
mybutton.addEventListener("click", backToTop);

//------------ back to top function ---------------------
function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}