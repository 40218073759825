$(document).ready(function () {
    window.checkRangeBox = function() {
        var minInput = parseFloat(document.getElementById("min_price").value);
        var maxInput = parseFloat(document.getElementById("max_price").value);

        //---------- isNaN checks for NOT a NUMBER -----------------------------
        if (isNaN(minInput) || isNaN(maxInput) || minInput <= 0 || maxInput <= 0) {
            swal.fire({
                title: "Please enter valid positive prices!",
                icon: "warning",
            });
            return false;
        } else if (minInput > maxInput) {
            swal.fire({
                title: "Lowest price should be less than highest price!",
                icon: "warning",
            });
            return false;
        } else {
            document.getElementById("priceRangeForm").submit();
        }
    };
});


