$(document).ready(function () {
    document.getElementById('bi-plus-square-dotted').addEventListener('click', openDialog);

    function openDialog() {
        document.getElementById('img-file').click();
    }

    document.getElementById('img-file').addEventListener('change', handleFileSelect);

    function handleFileSelect() {
        var input = document.getElementById('img-file');
        var output = document.getElementById('file-info');
        var files = input.files;

        output.innerHTML = '<p class="file-qty">' + files.length + ' file(s) selected:</p>';

        for (var i = 0; i < files.length; i++) {
            output.innerHTML += '<p class="file-name">' + files[i].name + '</p>';
        }
    }
});


