//--------- active header navbar --------------------------------
$(document).ready(function () {

    //------------- active terms & conditions menu left  ------------------
    $(".customer-service-section ul li a").on('click', function () {
        $(".customer-service-section li a.active").removeClass('active');
        $(this).addClass('active');

    })

    //------------- active current showing product -----------------
    $(".pagination li .page-link").on('click', function () {
        $(".pagination li .page-link.active").removeClass('active');
        $(this).addClass('active');

    })

});