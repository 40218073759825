$(document).ready(function () {
    //-------------- check and unchecked sidebar  -----------
    $('.hamburger-menu input').on('change', function () {
        if ($(this).is(':checked')) {
            $('.sidebar').css('left', '0');
            $('.sidebar-overlay').css('display', 'block');
        } else {
            $('.sidebar').css('left', '-300px');
            $('.sidebar-overlay').css('display', 'none');
        }
    });

    //------ close sidebar & searchbar using close icon ----------------------
    $('.close-btn, .close-icon').on('click', function () {
        $('.sidebar').css('left', '-300px');
        $('.box-filter-sidebar').css('left', '-370px');
        $('.searchbar').css('right', '-400px');
        $('.sidebar-overlay').css('display', 'none');
        $('.box-filter-sidebar-overlay').css('display', 'none');
        $('.searchbar-overlay').css('display', 'none');
        $('.hamburger-menu input').prop('checked', false);
    });

    //------- close and hide sidebar when click on page ---------------- 
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.sidebar').length &&
            !$(event.target).closest('.hamburger-menu').length) {
            $('.sidebar').css('left', '-300px');
            $('.sidebar-overlay').css('display', 'none');
            $('.hamburger-menu input').prop('checked', false);
        }
    });

    //------- close and hide searchbar when click on page  -------------------
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.searchbar').length &&
            !$(event.target).closest('#navbar-search-icon').length) {
            $('.searchbar').css('right', '-400px');
            $('.searchbar-overlay').css('display', 'none');
        }
    });

    //------- close and hide searchbar when click on page  -------------------
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.box-filter-sidebar').length &&
            !$(event.target).closest('.btn-open-filter').length) {
            $('.box-filter-sidebar').css('left', '-370px');
            $('.box-filter-sidebar-overlay').css('display', 'none');
        }
    });

    //-------- display search bar  ---------------
    $('#navbar-search-icon').on('click', function () {
        $('.searchbar').css('right', '0');
        $('.searchbar-overlay').css('display', 'block');
    })
});