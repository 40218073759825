$(document).ready(function () {
    $('.category-checkbox').change(function() {
        var subCategoryList = $(this).closest('li').find('.list-filter-checkbox');

        if ($(this).is(':checked')) {
            subCategoryList.slideDown();
        } else {
            subCategoryList.slideUp();
        }
    });

    //------- prevent default action for dropdown -----------
    $('.dropdown-menu').on('click', function (e) {
        e.stopPropagation();
    });

    //-------- categories checkbox active/ inactive --------
    $('.page-item .page-link').on('click', function () {
        $('.page-item .page-link').removeClass('active');
        $(this).addClass('active');
    });

    //-------- sidebar filter open and close ---------------------
    $('.btn-open-filter').on('click', function () {
        $('.box-filter-sidebar').css('display', 'block');
        $('.box-filter-sidebar').css('left', '0');
        $('.box-filter-sidebar-overlay').css('display', 'block');
    });

    //-------- sidebar filter closed when click on overlay -------------
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.btn-open-filter').length
            && !$(event.target).closest('.box-filter-sidebar').length) {
            $('.box-filter-sidebar').css('left', '-370px');
            $('.box-filter-sidebar-overlay').css('display', 'none');
        }
    });

    //-------- render products based on checked/ unchecked category and sub-category --------------
    function filterProducts() {
        var selectedCategories = $('.category-checkbox:checked').map(function () {
            return $(this).data('category-id');
        }).get();

        var selectedSubCategories = $('.sub-category-checkbox:checked').map(function () {
            return $(this).data('sub-category-id');
        }).get();

        $.ajax({
            url: productRoute,
            type: 'GET',
            data: {
                'categories_id': selectedCategories,
                'sub_categories_id': selectedSubCategories
            },
            success: function (data) {
                $('.hot-item-list').html($(data).find('.hot-item-list').html());
                $('.box-pagination').html($(data).find('.box-pagination').html());
            },
            error: function (error) {
                console.log('Error:', error);
            }
        });
    }

    // Trigger filterProducts function when category or sub-category checkboxes change
    $('.category-checkbox, .sub-category-checkbox').change(function () {
        filterProducts();
    });

    //-------- price range filter --------------
    $('#priceRangeForm button').click(function () {
        var minPrice = parseFloat($('#min_price').val()) || 0;
        var maxPrice = parseFloat($('#max_price').val()) || Infinity;

        $('.single-product').each(function () {
            var productPrice = parseFloat($(this).find('.hot-item-desc span').text().replace('RM ', ''));

            if (productPrice >= minPrice && productPrice <= maxPrice) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    });

});
