$(document).ready(function () {
    $('.banner-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        adaptiveHeight: true,
        prevArrow: "<div class='banner-arrow-prev'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-prev.svg' alt=''></span></div>",
        nextArrow: "<div class='banner-arrow-next'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-next.svg') }}' alt=''></span></div>",
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                }
            }
        ]
    });

    $('.customer-commment-slider').slick({
        dots: true,
        infinite: false,
        slidesToShow: 2,
        prevArrow: "<div class='banner-arrow-prev'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-prev.svg' alt=''></span></div>",
        nextArrow: "<div class='banner-arrow-next'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-next.svg') }}' alt=''></span></div>",
        slidesToScroll: 2,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    });

    $('.our-team-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 2,
        prevArrow: "<div class='banner-arrow-prev'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-prev.svg' alt=''></span></div>",
        nextArrow: "<div class='banner-arrow-next'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-next.svg') }}' alt=''></span></div>",
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: "<div class='banner-arrow-prev'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-prev.svg' alt=''></span></div>",
        nextArrow: "<div class='banner-arrow-next'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-next.svg') }}' alt=''></span></div>",
        scroll: true,
        asNavFor: '.slider-nav',
    });

    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        focusOnSelect: true,
    });

    $('.related-product-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: "<div class='banner-arrow-prev'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-prev.svg' alt=''></span></div>",
        nextArrow: "<div class='banner-arrow-next'> <span><img src='https://wp.alithemes.com/html/guza/demos/assets/imgs/template/icons/arrow-next.svg') }}' alt=''></span></div>",
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });
});


